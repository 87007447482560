/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import HeaderOptions from "./Common/HeaderOptions";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  return (
    <header>
      {/* <div className="nav-bar">
        <img src="assets/svg/left-2.svg" className="img-fluid" alt="" />
      </div> */}
      <a onClick={() => navigate("/")} className="brand-logo">
        <img src="assets/images/logo.png" className="img-fluid" alt="" />
      </a>
      <HeaderOptions />
    </header>
  );
}

export default Header;
