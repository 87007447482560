/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import HeaderOptions from "../Components/Common/HeaderOptions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProductsByCategoryId } from "../Utils/api-service";
import ProductLoader from "../Components/Common/ProductLoader";
import { extractIdFromGid } from "../Utils/categories-divide";
import AppContext from "../Utils/appContext";

export default function ProductList() {
  const navigate = useNavigate();
  const { showPrice } = React.useContext(AppContext);
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [nextCursor, setNextCursor] = React.useState();
  const [showLoadMore, setShowLoadMore] = React.useState(false);
  let [searchParams] = useSearchParams();
  const categoryName = searchParams.get("categoryName");
  const categoryId = searchParams.get("categoryId");

  React.useEffect(() => {
    if (categoryId) {
      fetchProducts(categoryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);
  const fetchProducts = (id) => {
    setLoading(true);
    try {
      let res = getProductsByCategoryId(id, nextCursor);
      res.then((data) => {
        if (data.status === 200) {
          const ps = data?.data?.collection?.products?.nodes || [];
          setProducts([...products, ...ps]);
          setShowLoadMore(
            data?.data?.collection?.products?.pageInfo?.hasNextPage
          );
          setNextCursor(data?.data?.collection?.products?.pageInfo?.endCursor);
          setLoading(false);
          // console.log(data);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const goToProduct = (id) => {
    const productId = extractIdFromGid(id);
    navigate("/product?productId=" + productId);
  };
  return (
    <>
      <header>
        <div className="back-links">
          <a onClick={() => navigate(-1)}>
            <i className="iconly-Arrow-Left icli"></i>
            <div className="content">
              <h2>
                <span>{categoryName || "Category"}</span>
              </h2>
            </div>
          </a>
        </div>
        <HeaderOptions />
      </header>
      <div
        className={
          categoryName?.length > 28
            ? "search-panel estimate-top-space px-15"
            : "search-panel top-space px-15"
        }
      ></div>

      <section className="px-15 lg-t-space">
        <div className="row gy-3 gx-3">
          {products?.length > 0 &&
            products?.map((product, idx) => {
              return (
                <div key={idx} className="col-6">
                  <div
                    onClick={() => goToProduct(product.id)}
                    className="product-box ratio_square"
                  >
                    <div className="img-part">
                      <a>
                        <img
                          src={
                            product?.featuredImage?.src ||
                            "assets/images/products/1000.jpg"
                          }
                          alt={product?.featuredImage?.altText || ""}
                          className="img-fluid bg-img"
                        />
                      </a>
                      {product?.totalInventory > 0 ? (
                        <label>new</label>
                      ) : (
                        <label>out of stock</label>
                      )}
                    </div>
                    <div className="product-content">
                      <a>
                        <h4>{product?.title}</h4>
                      </a>
                      {showPrice && (
                        <div className="price">
                          {product?.variants?.nodes?.length > 1 ? (
                            <h4>
                              ₹{product?.priceRange?.minVariantPrice?.amount}
                              <span> onwards </span>
                              {/* ₹{product?.priceRange?.maxVariantPrice?.amount} */}
                            </h4>
                          ) : (
                            <h4>
                              ₹{product?.priceRange?.minVariantPrice?.amount}
                            </h4>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
      {!loading && showLoadMore && (
        <div className="estimate-min-top-space" id="estimate-product-loader">
          <a
            onClick={() => fetchProducts(categoryId)}
            className="btn btn-danger"
          >
            Load More
          </a>
        </div>
      )}
      {loading && <ProductLoader />}
      <div
        className="modal filter-modal"
        id="filterModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Filters</h2>
              <a href="#" data-bs-dismiss="modal">
                <img src="assets/svg/x-dark.svg" className="img-fluid" alt="" />
              </a>
            </div>
            <div className="modal-body">
              <div className="filter-box">
                <h2 className="filter-title">Sort By:</h2>
                <div className="filter-content">
                  <select
                    className="form-select form-control form-theme"
                    aria-label="Default select example"
                  ></select>
                </div>
              </div>
              <div className="filter-box">
                <h2 className="filter-title">Brand:</h2>
                <div className="filter-content">
                  <ul className="row filter-row g-3">
                    <li className="col-6">
                      <div className="filter-col">Here & Now</div>
                    </li>
                    <li className="col-6">
                      <div className="filter-col">Zara</div>
                    </li>
                    <li className="col-6 active">
                      <div className="filter-col">Mast & harbour</div>
                    </li>
                    <li className="col-6">
                      <div className="filter-col">Tokyo talkies</div>
                    </li>
                    <li className="col-6">
                      <div className="filter-col">Vogue</div>
                    </li>
                    <li className="col-6">
                      <div className="filter-col">gucci</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-box">
                <h2 className="filter-title">Size:</h2>
                <div className="filter-content">
                  <ul className="row filter-row g-3">
                    <li className="col-4">
                      <div className="filter-col">small</div>
                    </li>
                    <li className="col-4">
                      <div className="filter-col">Medium</div>
                    </li>
                    <li className="col-4">
                      <div className="filter-col">large</div>
                    </li>
                    <li className="col-4">
                      <div className="filter-col">XL</div>
                    </li>
                    <li className="col-4">
                      <div className="filter-col">2XL</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-box">
                <h2 className="filter-title">Occasion:</h2>
                <div className="filter-content">
                  <ul className="row filter-row g-3">
                    <li className="col-6">
                      <div className="filter-col">Casual</div>
                    </li>
                    <li className="col-6">
                      <div className="filter-col">sports</div>
                    </li>
                    <li className="col-6">
                      <div className="filter-col">beach wear</div>
                    </li>
                    <li className="col-6">
                      <div className="filter-col">lounge wear</div>
                    </li>
                    <li className="col-6">
                      <div className="filter-col">formal</div>
                    </li>
                    <li className="col-6">
                      <div className="filter-col">party</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <a className="reset-link" data-bs-dismiss="modal">
                RESET
              </a>
              <a className="btn btn-solid" data-bs-dismiss="modal">
                apply filters
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="panel-space"></section>
    </>
  );
}
