import React from "react";
import Slider from "react-slick";
import ProductLoader from "./ProductLoader";
import { getBanners } from "../../Utils/api-service";
import { useNavigate } from "react-router-dom";

function SimpleSlide() {
  const [banners, setBanners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = () => {
    setLoading(true);
    try {
      let res = getBanners();
      res.then((response) => {
        if (response.status === 200) {
          setBanners(response?.data?.rows);
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const goTo = (link) => {
    if (link) {
      // Create a new URL object
      const url = new URL(link);

      // Get the pathname and search query
      const path = url.pathname + url.search;
      navigate(path);
    }
  };

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "30px",
    dots: true,
    arrows: false,
  };
  return (
    <section className="pt-0 home-section ratio_55">
      {loading && (
        <div>
          <div className="slider-box">
            <ProductLoader />
          </div>
        </div>
      )}
      {!loading && banners?.length === 1 && (
        <div>
          <div onClick={() => goTo(banners[0]?.link)} className="slider-box">
            <img
              src={banners[0]?.imageUrl}
              className="img-fluid bg-img"
              alt=""
            />
          </div>
        </div>
      )}
      {!loading && banners?.length > 1 && (
        <Slider
          className={"home-slider slick-default theme-dots"}
          {...settings}
        >
          {banners?.map((banner, idx) => {
            return (
              <div key={idx}>
                <div onClick={() => goTo(banner?.link)} className="slider-box">
                  <img
                    src={banner?.imageUrl}
                    className="img-fluid bg-img"
                    alt=""
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      )}
    </section>
  );
}

export default SimpleSlide;
