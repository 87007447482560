import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./Routes/PrivateRoute";
import Home from "./Containers/Home";
import ErrorPage from "./Error/ErrorPage";
import Login from "./Containers/Login";
import Search from "./Containers/Search";
import Categories from "./Containers/Categories";
import SubCategories from "./Containers/SubCategories";
import ProductList from "./Containers/ProductList";
import Product from "./Containers/ProductDetail";
import Cart from "./Containers/Cart";
import Delivery from "./Containers/Delivery";
import OrderSuccess from "./Containers/OrderSuccess";
import OrderAgain from "./Containers/OrderAgain";
import Profile from "./Containers/Profile";
import { getAllCategories } from "./Utils/api-service";

function App() {
  const router = createBrowserRouter([
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "onboard",
      element: <Login />,
    },
    {
      path: "/",
      element: <PrivateRoute path="/" component={Home} />,
      exact: true,
      errorElement: <PrivateRoute path="/" component={ErrorPage} />,
      loader: getAllCategories,
    },
    {
      path: "categories",
      element: <PrivateRoute component={Categories} />,
      errorElement: <PrivateRoute component={ErrorPage} />,
    },
    {
      path: "sub-category",
      element: <PrivateRoute component={SubCategories} />,
      errorElement: <PrivateRoute component={ErrorPage} />,
    },
    {
      path: "product-list",
      element: <PrivateRoute path="/" component={ProductList} />,

      errorElement: <PrivateRoute path="/" component={ErrorPage} />,
    },
    {
      path: "product",
      element: <PrivateRoute path="/" component={Product} />,
      errorElement: <PrivateRoute path="/" component={ErrorPage} />,
    },
    {
      path: "cart",
      element: <PrivateRoute path="/" component={Cart} />,
      errorElement: <PrivateRoute path="/" component={ErrorPage} />,
    },
    {
      path: "delivery",
      element: <PrivateRoute path="/" component={Delivery} />,
      errorElement: <PrivateRoute path="/" component={ErrorPage} />,
    },
    {
      path: "order-success",
      element: <PrivateRoute path="/" component={OrderSuccess} />,
      errorElement: <PrivateRoute path="/" component={ErrorPage} />,
    },
    {
      path: "order-again",
      element: <PrivateRoute path="/" component={OrderAgain} />,
      errorElement: <PrivateRoute path="/" component={ErrorPage} />,
    },
    {
      path: "search",
      element: <PrivateRoute path="/" component={Search} />,
      errorElement: <PrivateRoute path="/" component={ErrorPage} />,
    },
    {
      path: "profile",
      element: <PrivateRoute path="/" component={Profile} />,
      errorElement: <PrivateRoute path="/" component={ErrorPage} />,
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
