/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import AppContext from "../Utils/appContext";
import { useNavigate } from "react-router-dom";

function CategoryGrid() {
  const navigate = useNavigate();
  const { categories } = React.useContext(AppContext);

  const goTo = (category) => {
    if (category?.haveChild) {
      return navigate("/sub-category?categoryID=" + category.mainId);
    }
    return navigate(
      "/product-list?categoryName=" +
        category.title +
        "&categoryId=" +
        category?.mainId
    );
  };
  return (
    <>
      <section className="pt-2 tab-section">
        <div className="title-section px-15">
          <h2>Shop By Category</h2>
          <h3>Your Gateway to Variety!</h3>
        </div>
        <div className="tab-section">
          <div className="tab-content px-15">
            <div className="tab-pane fade show active" id="trending">
              <div className="row gy-3 gx-3">
                {categories.map((category, idx) => (
                  <div key={idx} className="col-md-4 col-6">
                    <div
                      onClick={() => goTo(category)}
                      className="product-box ratio_square"
                    >
                      <div className="img-part">
                        <a>
                          <img
                            src={
                              category?.image?.url ||
                              "assets/images/products/4.jpg"
                            }
                            alt=""
                            className="img-fluid bg-img"
                          />
                        </a>
                      </div>
                      <div className="product-content">
                        <a>
                          <h4>{category.title}</h4>
                        </a>
                        {/* <div className="price">
                          <h4>subtitle</h4>
                        </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CategoryGrid;
