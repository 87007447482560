/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../Utils/appContext";
import { getPaginatedEstimateAgainProducts } from "../Utils/api-service";
import { extractIdFromGid } from "../Utils/categories-divide";
import ProductLoader from "../Components/Common/ProductLoader";
import ProductDetailOffCanvas from "./ProductDetailOffCanvas";

const LIMIT_PRODUCTS = 20;
export default function OrderAgain() {
  const [vendorID] = React.useState(localStorage.getItem("vId"));
  const navigate = useNavigate();
  const { showPrice } = React.useContext(AppContext);
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showLoadMore, setShowLoadMore] = React.useState(false);
  const [selectedProductId, setSelectedProductId] = React.useState();
  const [selectedProductTitle, setSelectedProductTitle] = React.useState();
  const [limit] = React.useState(LIMIT_PRODUCTS);
  const [offSet, setOffSet] = React.useState(0);
  React.useEffect(() => {
    if (vendorID) {
      fetchProducts(vendorID, limit, offSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorID]);
  const fetchProducts = (vendorID, limit, offSet) => {
    setLoading(true);
    try {
      let res = getPaginatedEstimateAgainProducts(vendorID, limit, offSet);
      res.then((data) => {
        if (data.status === 200) {
          const ps = data?.data?.data?.nodes || [];
          setProducts([...products, ...ps]);
          const checkProduct = [...products, ...ps];
          const counts = data?.data?.count;
          setShowLoadMore(counts > checkProduct?.length);
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddMore = () => {
    setOffSet(offSet + 1);
    fetchProducts(vendorID, limit, offSet + 1);
  };

  const goToProduct = (id) => {
    const productId = extractIdFromGid(id);
    navigate("/product?productId=" + productId);
  };

  const openProductOffcanvas = (id, title) => {
    const productId = extractIdFromGid(id);
    setSelectedProductId(productId);
    setSelectedProductTitle(title);
  };

  const clearOffCanvas = () => {
    $("#removecart").offcanvas("hide");
    setSelectedProductId();
    const toastElement = document.getElementById("myToast");
    const toast = new bootstrap.Toast(toastElement);
    toast.show();
    setTimeout(() => {
      toast.hide();
    }, 2000);
  };
  return (
    <>
      <section className="cart-section pt-10 top-space">
        {products?.length > 0 &&
          products?.map((product, idx) => {
            if (product?.totalInventory === 0) {
              return null;
            }
            return (
              <div key={idx}>
                <div className="cart-box px-15">
                  <a
                    onClick={() => goToProduct(product.id)}
                    className="cart-img"
                  >
                    <img
                      src={
                        product?.featuredImage?.src ||
                        "assets/images/products/1000.jpg"
                      }
                      alt={product?.featuredImage?.altText || ""}
                      className="img-fluid"
                    />
                  </a>
                  <div className="cart-content">
                    <a onClick={() => goToProduct(product.id)}>
                      <h4>{product?.title}</h4>
                    </a>
                    {showPrice && (
                      <div className="price">
                        {product?.variants?.nodes?.length > 1 ? (
                          <h4>
                            ₹{product?.priceRange?.minVariantPrice?.amount}
                            <span> onwards </span>
                            {/* ₹{product?.priceRange?.maxVariantPrice?.amount} */}
                          </h4>
                        ) : (
                          <h4>
                            ₹{product?.priceRange?.minVariantPrice?.amount}
                          </h4>
                        )}
                      </div>
                    )}
                    <div className="cart-option">
                      <button
                        data-bs-toggle="offcanvas"
                        data-bs-target="#removecart"
                        type="button"
                        className="w-100 btn btn-solid"
                        onClick={() =>
                          openProductOffcanvas(product.id, product?.title)
                        }
                      >
                        Add to cart
                      </button>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
              </div>
            );
          })}
      </section>
      {!loading && showLoadMore && (
        <div className="estimate-min-top-space" id="estimate-product-loader">
          <a onClick={() => handleAddMore()} className="btn btn-danger">
            Load More
          </a>
        </div>
      )}
      {loading && <ProductLoader />}
      <section className="service-wrapper px-15 pt-0">
        <div className="row">
          <div className="col-4">
            <div className="service-wrap">
              <div className="icon-box">
                <img
                  src="assets/svg/about/diamond.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <span>Super Quality</span>
            </div>
          </div>
          <div className="col-4">
            <div className="service-wrap">
              <div className="icon-box">
                <img
                  src="assets/svg/24-hours.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <span>24/7 Support</span>
            </div>
          </div>
          <div className="col-4">
            <div className="service-wrap">
              <div className="icon-box">
                <img src="assets/svg/wallet.svg" className="img-fluid" alt="" />
              </div>
              <span>Best Price</span>
            </div>
          </div>
        </div>
      </section>
      <section className="panel-space"></section>
      <div
        id="myToast"
        class="toast align-items-center text-white bg-primary border-0 position-fixed bottom-50"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="d-flex">
          <div class="toast-body">
            <strong> {selectedProductTitle} is added to cart. </strong>
          </div>
          <button
            type="button"
            class="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      </div>

      {/* <!-- remove item canvas start --> */}
      <div
        className="offcanvas offcanvas-bottom h-auto removecart-canvas"
        id="removecart"
      >
        <div className="offcanvas-body small">
          <div className="content">
            <ProductDetailOffCanvas
              estimateAgainProductId={selectedProductId}
              clearOffCanvas={clearOffCanvas}
            />
          </div>
        </div>
      </div>
      {/* <!-- remove item canvas end --> */}
    </>
  );
}
