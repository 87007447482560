// estimatePROD
const domainPort = "/";
// estimate LOCAL
// const domainPort = "http://localhost:3002/";
export const login_api_url = domainPort + "shop";
export const api_url = domainPort + "api/shop";
export const apiTimeOut = 600;

export const possibleNames = [
  "pack size",
  "pack size:",
  "pack qty",
  "pack quantity",
  "pack quantity:",
  "pack quantity.",
  "pack quantity.:",
  "pack qty.",
  "pack qty.:",
  "pack sizes",
  "pack sizes:",
];
