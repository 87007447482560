/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import * as React from "react";
import CategoryGrid from "../Components/CategoryGrid";
import AppContext from "../Utils/appContext";
import { getAllCategories } from "../Utils/api-service";
import { divideCategories } from "../Utils/categories-divide";

export default function Categories() {
  const { categories, setCategories, setSubCategories, setOtherCategories } =
    React.useContext(AppContext);
  React.useEffect(() => {
    if (categories.length === 0) {
      const res = getAllCategories();
      res.then((data) => {
        if (data.status === 200) {
          const { parentCategories, childCategories, otherCategories } =
            divideCategories(data.data?.collections.nodes);
          setCategories(parentCategories);
          setSubCategories(childCategories);
          setOtherCategories(otherCategories);
        }
      });
    }
  });

  React.useEffect(() => {
    $(".loader").fadeOut("slow");
    $(".loader").remove("slow");
  }, []);
  return (
    <>
      <div className="loader">
        <span></span>
        <span></span>
      </div>
      <section id="estimate-panel-space"></section>
      <CategoryGrid />
      <section className="panel-space"></section>
    </>
  );
}
