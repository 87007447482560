import { apiTimeOut } from "./constant";
export let category;
export const limitApiCall = (callback) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      callback.apply(context, args);
    }, apiTimeOut);
  };
};
export const setAvailableCategory = (key) => {
  category = key;
};

// change in this require change with adminportal
export const extractNumberFromString = (str) => {
  if (!str) {
    return {};
  }
  const match = str.match(/^(\d+)\s*(\D.*)$/);
  if (match) {
    const number = parseInt(match[1], 10);
    const unit = match[2].trim();
    return { number, unit };
  }
  return { number: str, unit: "PC" };
};

export const getLocalStorage = (key) => localStorage.getItem(key);

export const clearAllLocalStorage = () => localStorage.clear();

export const formatDate = (date) => {
  if (!date) {
    return "NA";
  }
  const mainDate = new Date(date);

  // Define options for formatting
  const options = {
    weekday: "long", // long or short
    year: "numeric", // numeric or 2-digit
    month: "long", // long, short, or numeric
    day: "numeric", // numeric or 2-digit
  };

  // Format the date
  const formattedDate = mainDate.toLocaleDateString("en-US", options);
  return formattedDate;
};
