/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "../Utils/appContext";
import { getCart } from "../Utils/cart-service";

function BottomNavigation() {
  const { cartLength } = React.useContext(AppContext);
  const [length, setLength] = React.useState();
  React.useEffect(() => {
    const carts = getCart();
    setLength(carts?.length);
  }, [cartLength]);
  let urllocation = useLocation();
  const navigate = useNavigate();
  const [location, setLocation] = React.useState({});
  React.useEffect(() => {
    setLocation(urllocation);
  }, [urllocation]);

  const checkStatus = (urls) => {
    if (typeof urls === "string") {
      return location.pathname === urls ? "active" : "";
    }
    if (Array.isArray(urls)) {
      const val = urls.includes(location.pathname);
      return val ? "active" : "";
    }
  };
  return (
    <div className="bottom-panel">
      <ul>
        <li className={checkStatus("/")}>
          <a onClick={() => navigate("/")}>
            <div className="icon">
              <i className="iconly-Home icli"></i>
              <i className="iconly-Home icbo"></i>
            </div>
            <span>home</span>
          </a>
        </li>
        <li
          className={checkStatus([
            "/categories",
            "/sub-category",
            "/product-list",
            "/product",
          ])}
        >
          <a onClick={() => navigate("/categories")}>
            <div className="icon">
              <i className="iconly-Category icli"></i>
              <i className="iconly-Category icbo"></i>
            </div>
            <span>category</span>
          </a>
        </li>
        <li className={checkStatus("/search")}>
          <a onClick={() => navigate("/search")}>
            <div className="icon">
              <i className="iconly-Search icli"></i>
              <i className="iconly-Search icbo"></i>
            </div>
            <span>search</span>
          </a>
        </li>
        <li className={checkStatus("/order-again")}>
          <a onClick={() => navigate("/order-again")}>
            <div className="icon">
              <i className="iconly-Heart icli"></i>
              <i className="iconly-Heart icbo"></i>
            </div>
            <span>Again</span>
          </a>
        </li>
        <li id="estimate-relative" className={checkStatus("/cart")}>
          <a onClick={() => navigate("/cart")}>
            {length > 0 && <span id="cart_bottom_navigation">{length}</span>}
            <div className="icon">
              <i className="iconly-Buy icli"></i>
              <i className="iconly-Buy icbo"></i>
            </div>
            <span>cart</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default BottomNavigation;
