// cartService.js

const CART_KEY = "cart";

// Get cart from local storage
export const getCart = () => {
  const cart = localStorage.getItem(CART_KEY);
  return cart ? JSON.parse(cart) : [];
};

// Set cart to local storage
export const setCart = (cart) => {
  localStorage.setItem(CART_KEY, JSON.stringify(cart));
};

// Add item to cart
export const addItemToCart = (item) => {
  const cart = getCart();
  cart.push(item);
  setCart(cart);
};

// Remove item from cart by index
export const removeItemFromCart = (index) => {
  const cart = getCart();
  cart.splice(index, 1);
  setCart(cart);
};

// Clear cart
export const clearCart = () => {
  localStorage.removeItem(CART_KEY);
};
