/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Loader from "../Components/Common/Loader";
import { useNavigate } from "react-router-dom";
import AppContext from "../Utils/appContext";
import { getCart } from "../Utils/cart-service";
import {
  getAddressesByVendorId,
  postCreateEstimate,
} from "../Utils/api-service";

export default function Delivery() {
  const { showPrice } = React.useContext(AppContext);
  const navigate = useNavigate();
  const [fullCart] = React.useState(getCart());
  const [vendorID] = React.useState(localStorage.getItem("vId"));
  const [phone] = React.useState(localStorage.getItem("phone"));
  const [addresses, setAddresses] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState("");
  const [selectedAddressIndex, setSelectedAddressIndex] = React.useState();
  const [totalAmount, setTotalAmount] = React.useState();
  const [showLoad, setShowLoad] = React.useState(false);
  const [deliveryInstruction, setDeliveryInstruction] = React.useState("");
  const [estimate, setEstimate] = React.useState({});
  React.useEffect(() => {
    try {
      setShowLoad(true);
      let res = getAddressesByVendorId(vendorID);
      res.then((data) => {
        if (data.status === 200) {
          setAddresses(data?.data?.addresses);
          if (data?.data?.addresses?.length > 0) {
            setSelectedAddress(data?.data?.addresses[0]);
            setSelectedAddressIndex(0);
          }
        } else {
          setAddresses([]);
        }
        setShowLoad(false);
      });
    } catch (error) {
      setAddresses([]);
      setShowLoad(false);
      console.error("Error fetching data:", error);
    }
  }, [vendorID]);

  React.useEffect(() => {
    let total = 0;
    fullCart?.forEach((product) => {
      total += product.totalPrice;
    });
    setTotalAmount(total);
    setEstimate((prevData) => ({
      ...prevData,
      cart: fullCart,
      phone: phone,
      totalQuantity: fullCart?.length,
      totalAmount: total,
    }));
    console.log("fullCart", fullCart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCart]);

  const handleSelect = (idx) => {
    let index = idx;
    if (selectedAddressIndex === idx) {
      index = null;
    }
    setSelectedAddress(index ? addresses[index] : "");
    setSelectedAddressIndex(index);
  };

  const sendEstimate = () => {
    const esti = estimate;
    esti.shippingAddress = selectedAddress;
    esti.deliveryInstruction = deliveryInstruction;
    console.log("estimate", esti);
    try {
      setShowLoad(true);
      let res = postCreateEstimate(esti);
      res.then((data) => {
        if (data.status === 200) {
          console.log("RES", data);
          const id = data?.data?.id;
          navigate("/order-success?estimateId=" + id, { state: data?.data });
        }
        setShowLoad(false);
      });
    } catch (error) {
      setShowLoad(false);
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      {showLoad && <Loader />}
      <header>
        <div className="back-links">
          <a onClick={() => navigate(-1)}>
            <i className="iconly-Arrow-Left icli"></i>
            <div className="content">
              <h2>Delivery Details</h2>
              <h6>Step 2 of 2</h6>
            </div>
          </a>
        </div>
      </header>
      <section className="top-space px-15">
        <div className="delivery-option-section">
          {addresses && addresses.length > 0 && (
            <section className="px-15 alert-classic pt-0">
              <div
                className="alert alert-danger d-flex align-items-center"
                role="alert"
              >
                <i className="iconly-Info-Square icli"></i>
                <div>Selecting any address is optional!</div>
              </div>
            </section>
          )}
          <ul>
            {addresses &&
              addresses.length > 0 &&
              addresses.map((address, idx) => {
                return (
                  <li
                    key={idx}
                    id={selectedAddressIndex === idx ? "estimate-selected" : ""}
                    onClick={() => handleSelect(idx)}
                  >
                    <div className="check-box active">
                      <div className="form-check d-flex ps-0">
                        <div>
                          <h4 className="name">Address {idx + 1}</h4>
                          <div className="addess">
                            <h4>{address}</h4>
                          </div>
                          {selectedAddressIndex === idx && (
                            <h6 className="label">SELECTED</h6>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </section>
      <div className="divider"></div>
      <section className="px-15 ratio_45 pt-0">
        <div>
          <div className="form-floating mb-4">
            <textarea
              row="10"
              className="form-control"
              id="estimate-textrea"
              placeholder="Full Name"
              value={deliveryInstruction}
              onChange={(e) => setDeliveryInstruction(e.target?.value)}
            />
            <label htmlFor="one">Delivery Instruction?</label>
          </div>
        </div>
      </section>
      <div id="estimate-zindex-2" className="delivery-cart cart-bottom">
        <div>
          <div className="left-content">
            {showPrice && <h4>₹{totalAmount}</h4>}
            <a onClick={() => navigate(-1)} className="theme-color">
              View details
            </a>
          </div>

          <a onClick={() => sendEstimate()} className="btn btn-solid">
            SEND ESTIMATE
          </a>
        </div>
      </div>
    </>
  );
}
