/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { getCart } from "../../Utils/cart-service";
import AppContext from "../../Utils/appContext";
import { useNavigate } from "react-router-dom";

const HeaderOptions = ({ hideCart, hideProfile, showHome }) => {
  const navigate = useNavigate();
  const { cartLength } = React.useContext(AppContext);
  const [length, setLength] = React.useState();
  React.useEffect(() => {
    const carts = getCart();
    setLength(carts?.length);
  }, [cartLength]);
  return (
    <div className="header-option">
      <ul>
        <li>
          <a onClick={() => navigate("/search")}>
            <i className="iconly-Search icli"></i>
          </a>
        </li>
        {!hideProfile && (
          <li>
            <a onClick={() => navigate("/profile")}>
              <i className="iconly-Profile icli"></i>
            </a>
          </li>
        )}
        {!hideCart && (
          <li>
            <a onClick={() => navigate("/cart")}>
              <i className="iconly-Buy icli"></i>
            </a>
            {length > 0 && <span id="cart_bottom_header">{length}</span>}
          </li>
        )}
        {showHome && (
          <li>
            {/* <a onClick={() => navigate("/")}>
              <i className="iconly-Home icli"></i>
            </a> */}
            <button onClick={() => navigate("/")} className="btn btn-solid">
              ADD MORE ITEMS
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default HeaderOptions;
