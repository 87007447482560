/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSearch } from "../Utils/api-service";
import { limitApiCall } from "../Utils/util-service";
import ProductLoader from "../Components/Common/ProductLoader";
import { divideCategories, extractIdFromGid } from "../Utils/categories-divide";

export default function Search() {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = React.useState(
    searchParams.get("q") || ""
  );
  const [loading, setLoading] = React.useState(false);
  const [collections, setCollections] = React.useState([]);
  const [subCollections, setSubCollections] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [noRcrd, setNoRcrd] = React.useState(false);
  React.useEffect(() => {
    setNoRcrd(false);
    if (searchText?.length > 1 && searchText?.length < 249) {
      limitSearch(searchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  const fetchSearch = (text) => {
    setLoading(true);
    try {
      let res = getSearch(text);
      res.then((data) => {
        if (data.status === 200) {
          // setSearchParams("q", searchText);
          setSearchParams({ q: text });
          const { parentCategories, childCategories } = divideCategories(
            data?.data?.predictiveSearch?.collections
          );
          setCollections(parentCategories);
          setSubCollections(childCategories);

          setProducts(data?.data?.predictiveSearch?.products || []);
          setNoRcrd(
            data?.data?.predictiveSearch?.collections?.length === 0 &&
              data?.data?.predictiveSearch?.products?.length === 0
          );
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const limitSearch = React.useCallback(limitApiCall(fetchSearch), []);

  const formatTitle = (t) => {
    const [, child] = t.split(" > ");
    return child || null;
  };

  const goTo = (category) => {
    if (category?.haveChild) {
      return navigate("/sub-category?categoryID=" + category.mainId);
    }
    let title = category.title;
    if (category.title.includes(">")) {
      const [, child] = title.split(" > ");
      title = child;
    }

    return navigate(
      "/product-list?categoryName=" + title + "&categoryId=" + category?.mainId
    );
  };

  const goToProduct = (id) => {
    const productId = extractIdFromGid(id);
    navigate("/product?productId=" + productId);
  };
  return (
    <>
      {/* <Loader /> */}
      <div className="divider t-20 b-20"></div>
      <div className="search-panel w-back pt-3 px-15">
        <a onClick={() => navigate(-1)} className="back-btn">
          <i className="iconly-Arrow-Left icli"></i>
        </a>
        <div className="search-bar">
          <input
            type="search"
            className="form-control form-theme"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            autoFocus
          />
          <i className="iconly-Search icli search-icon"></i>
        </div>
      </div>
      {loading && <ProductLoader />}
      {!loading && (
        <>
          {noRcrd && products?.length === 0 && collections?.length === 0 && (
            <section
              id="estimate-flex-center"
              className="px-15 recent-search-section"
            >
              <h4 className="page-title">NO RECORDS</h4>
            </section>
          )}
          {(collections?.length > 0 || subCollections?.length > 0) && (
            <section className="px-15 recent-search-section">
              <h4 className="page-title">CATEGORIES</h4>
              <ul>
                {collections.map((collection, idx) => {
                  return (
                    <li key={idx}>
                      <a onClick={() => goTo(collection)}>
                        <i className="iconly-Arrow-Right icli"></i>
                        {collection?.title}
                      </a>
                    </li>
                  );
                })}
                {subCollections?.map((subCollection, idx) => {
                  return (
                    <li key={idx}>
                      <a onClick={() => goTo(subCollection)}>
                        <i className="iconly-Arrow-Right icli"></i>
                        {formatTitle(subCollection?.title)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </section>
          )}
          {products?.length > 0 && (
            <section className="px-15 recent-search-section">
              <h4 className="page-title">PRODUCTS</h4>
              <ul>
                {products.map((product, idx) => {
                  return (
                    <li key={idx}>
                      <a onClick={() => goToProduct(product.id)}>
                        <i className="iconly-Arrow-Right icli"></i>
                        {product?.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </section>
          )}
          {products?.length > 0 && collections?.length > 0 && (
            <>
              <div className="divider t-12 b-20"></div>
              <div className="divider t-12 b-20"></div>
            </>
          )}
        </>
      )}
    </>
  );
}
