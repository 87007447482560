/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React from "react";
import { useNavigate } from "react-router-dom";

function EmptyCart() {
  const navigate = useNavigate();
  return (
    <section className="px-15">
      <div className="empty-cart-section text-center">
        <img src="assets/images/empty-cart.png" className="img-fluid" alt="" />
        <h2>Whoops !! Cart is Empty</h2>
        <p>
          Looks like you haven’t added anything to your cart yet. You will find
          a lot of interesting products using our “Category” page
        </p>
        <a
          onClick={() => navigate("/categories")}
          className="btn btn-solid w-100"
        >
          start shopping
        </a>
      </div>
    </section>
  );
}

export default EmptyCart;
