/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import AppContext from "../Utils/appContext";
import { useNavigate } from "react-router-dom";
import { setPrice } from "../Utils/price-service";

export default function Profile() {
  const navigate = useNavigate();
  const { setShowPrice, showPrice } = React.useContext(AppContext);
  const [isChecked, setIsChecked] = React.useState(showPrice);
  const [name, setName] = React.useState();
  const [phone, setPhone] = React.useState();
  const [showAcrossPrices, setShowAcrossPrices] = React.useState();
  // Handler to toggle the checkbox state
  const handleCheckboxChange = (event) => {
    const val = event?.target?.checked;
    setIsChecked(val);
    setShowPrice(val);
    setPrice(val);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  React.useEffect(() => {
    const data = localStorage.getItem("isShowPriceEnabled");
    const name = localStorage.getItem("name");
    const phone = localStorage.getItem("phone");
    if (data) {
      setShowAcrossPrices(JSON.parse(data));
    } else {
      setShowAcrossPrices(true);
    }
    setName(name);
    setPhone(phone);
  }, []);
  return (
    <>
      {/* <Loader /> */}
      <header>
        <div className="back-links">
          <a onClick={() => navigate(-1)}>
            <i className="iconly-Arrow-Left icli"></i>
            <div className="content">
              <h2>Profile</h2>
            </div>
          </a>
        </div>
      </header>
      <section className="top-space pt-0">
        <div className="profile-detail">
          <div className="media">
            <img src="assets/images/user/3.png" className="img-fluid" alt="" />
            <div className="media-body">
              <h2>{name}</h2>
              <h6>{phone}</h6>
            </div>
          </div>
        </div>
      </section>
      <div className="sidebar-content">
        <ul className="link-section">
          {showAcrossPrices && (
            <li>
              <div>
                <i className="iconly-Wallet icli"></i>
                <div className="content toggle-sec w-100">
                  <div>
                    <h4>Show Prices</h4>
                    <h6>Hide prices from products/cart..</h6>
                  </div>
                  <div className="button toggle-btn ms-auto">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <div className="knobs">
                      <span></span>
                    </div>
                    <div className="layer"></div>
                  </div>
                </div>
              </div>
            </li>
          )}
          {/* <li>
            <a href="order-history">
              <i className="iconly-Document icli"></i>
              <div className="content">
                <h4>Estimates</h4>
                <h6>Ongoing Estimates, Recent Estimates..</h6>
              </div>
            </a>
          </li> */}
        </ul>
        <div className="divider"></div>
        <ul className="link-section">
          <li>
            <a>
              <i className="iconly-Info-Square icli"></i>
              <div className="content">
                <h4>Terms & Conditions</h4>
                <h6>Terms for use of Platform</h6>
              </div>
            </a>
          </li>
          <li>
            <a>
              <i className="iconly-Call icli"></i>
              <div className="content">
                <h4>Help/Customer Care</h4>
                <h6>Customer Support</h6>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div className="px-15">
        <a
          onClick={() => handleLogout()}
          className="btn btn-outline w-100 content-color"
        >
          LOG OUT
        </a>
      </div>
      <section className="panel-space"></section>
    </>
  );
}
