const PRICE_KEY = "price";

// Get cart from local storage
export const getPrice = () => {
  const price = localStorage.getItem(PRICE_KEY);
  if (!price || price === "undefined") {
    return true;
  }
  return JSON.parse(price);
};

// Set cart to local storage
export const setPrice = (price) => {
  localStorage.setItem(PRICE_KEY, JSON.stringify(price));
};
