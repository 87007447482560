import { api_url, login_api_url } from "./constant";
import { getLocalStorage } from "./util-service";
import axios from "axios";

let authToken;

function getAuthDetails() {
  authToken = getLocalStorage("authToken");
}

// UN-AUTHENTICATION interceptors
axios.interceptors.response.use(
  (response) => {
    // If the request succeeds, return the response

    return response;
  },
  (error) => {
    // If the request fails
    if (error.response.status === 401 || error.response.status === 403) {
      // Handle 401 status code here, for example, redirect to login page
      console.log("Unauthorized. Redirecting to login page...");
      // Example: navigate to login page
      window.location.href = "/login";
    }
    // Return any error so it can be caught by the calling function
    return Promise.reject(error);
  }
);

export const postLogin = async (obj) => {
  try {
    const response = await axios.post(`${login_api_url}/signin`, obj);
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
    return error;
  }
};

export const getUserTokenVerify = async (token) => {
  try {
    const response = await axios.get(`${login_api_url}/auth/verify-customer`, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
    return error;
  }
};

export const getAllCategories = async () => {
  getAuthDetails();
  try {
    const response = await axios.get(`${api_url}/collections`, {
      headers: {
        "x-access-token": authToken,
      },
    });
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

export const getProductsByCategoryId = async (id, cursor) => {
  getAuthDetails();
  try {
    const response = await axios.get(
      `${api_url}/collection/${id}/products?cursor=${cursor}`,
      {
        headers: {
          "x-access-token": authToken,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

export const getProductById = async (id) => {
  getAuthDetails();
  try {
    const response = await axios.get(`${api_url}/product?id=${id}`, {
      headers: {
        "x-access-token": authToken,
      },
    });
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

export const getProductRecomendation = async (id) => {
  getAuthDetails();
  try {
    const response = await axios.get(
      `${api_url}/product-recommendations?id=${id}`,
      {
        headers: {
          "x-access-token": authToken,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

export const getSearch = async (text) => {
  getAuthDetails();
  try {
    const response = await axios.get(`${api_url}/search?searchText=${text}`, {
      headers: {
        "x-access-token": authToken,
      },
    });
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

export const getAddressesByVendorId = async (id) => {
  getAuthDetails();
  try {
    const response = await axios.get(`${api_url}/address?id=${id}`, {
      headers: {
        "x-access-token": authToken,
      },
    });
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

// Create Estimate

export const postCreateEstimate = async (obj) => {
  getAuthDetails();
  try {
    const response = await axios.post(`${api_url}/create-estimate`, obj, {
      headers: {
        "x-access-token": authToken,
      },
    });
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
  }
};

// Get Estimate again products

export const getPaginatedEstimateAgainProducts = async (
  vendorID,
  limit,
  offset
) => {
  getAuthDetails();
  try {
    const response = await axios.get(
      `${api_url}/estimate-again/${vendorID}?limit=${limit}&offset=${offset}`,
      {
        headers: {
          "x-access-token": authToken,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
    return error;
  }
};

// Get App Banners

export const getBanners = async () => {
  getAuthDetails();
  try {
    const response = await axios.get(`${api_url}/banner`, {
      headers: {
        "x-access-token": authToken,
      },
    });
    return response;
  } catch (error) {
    console.error("Error sending data:", error);
  }
};
