export const extractIdFromGid = (gid) => {
  // Split the GID by "/"
  if (!gid) {
    return;
  }
  const parts = gid?.split("/");
  // Get the last part which should be the ID
  const id = parts[parts.length - 1];
  return id;
};

export const divideCategories = (collections) => {
  let parentCategories = [];
  let childCategories = [];
  let otherCategories = [];
  const nodes = collections;
  nodes?.forEach((collection) => {
    const string = collection?.title;
    if (string.includes(">")) {
    } else if (string.startsWith("__")) {
      otherCategories.push(collection);
    } else {
      collection.mainId = extractIdFromGid(collection.id);
      parentCategories.push(collection);
    }
  });

  function findParentId(name) {
    let foundCollectionId = null;
    let found = false;
    nodes?.forEach((collection) => {
      if (found) return; // Skip iteration if already found
      const title = collection?.title;
      if (title === name) {
        collection.haveChild = true;
        foundCollectionId = collection.mainId;
        found = true; // Set the flag to true to break out of loop
      }
    });
    return foundCollectionId;
  }

  nodes?.forEach((collection) => {
    const string = collection?.title;
    if (string.includes(">")) {
      const [parent, child] = string.split(" > ");
      if (child) {
        collection.subTitle = child.trim();
        collection.mainId = extractIdFromGid(collection?.id);
        collection.parentTitle = parent.trim();
        collection.parentMainId = findParentId(parent.trim());
        collection.parentGID = childCategories.push(collection);
      }
    }
  });
  return { parentCategories, childCategories, otherCategories };
};
