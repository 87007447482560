import { createContext } from "react";

const AppContext = createContext({
  categories: [],
  setCategories: () => {},
  subCategories: [],
  setSubCategories: () => {},
  otherCategories: [],
  setOtherCategories: () => {},
  cartLength: "",
  setCartLength: () => {},
  showPrice: undefined,
  setShowPrice: () => {},
});

export default AppContext;
