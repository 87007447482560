import * as React from "react";
import Loader from "../Components/Common/Loader";

export default function ErrorPage() {
  return (
    <>
      <Loader />
      <section className="px-15">
        <div className="empty-cart-section text-center">
          <img src="assets/images/page-404.png" className="img-fluid" alt="" />
          <h1>404 Page not found !!</h1>
          <div className="mt-5">
            <a href="/" className="btn btn-solid w-100">
              back to home page
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
