import React from "react";
import { Navigate } from "react-router-dom";
import { checkTokenAvailable } from "../Utils/verify-user";
import MainLayout from "../Containers/MainLayout";
function PrivateRoute({ component: Component, ...rest }) {
  return checkTokenAvailable() ? (
    <MainLayout component={Component} />
  ) : (
    <Navigate to="/login" replace={true} />
  );
}
export default PrivateRoute;
