/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import AppContext from "../Utils/appContext";
import { useSearchParams, useNavigate } from "react-router-dom";

let backUpCategory = [];
const SubCategoryGrid = React.memo(() => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [subCats, setSubCats] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [mainCatgeory, setMainCategory] = React.useState("");

  const { categories, subCategories } = React.useContext(AppContext);
  console.log(categories, subCategories);
  React.useEffect(() => {
    const subCollections = [];
    const parentCategoryId = searchParams.get("categoryID");
    const filterArr = [];
    subCategories.forEach((collection) => {
      if (collection.parentMainId === parentCategoryId) {
        subCollections.push(collection);
        if (
          collection?.description &&
          !filterArr.includes(collection.description)
        ) {
          filterArr.push(collection.description);
        }
      }
    });
    setSubCats(subCollections);
    backUpCategory = subCollections;
    setMainCategory(subCollections[0]?.parentTitle);
    setFilters(filterArr);
  }, [subCategories]);

  const filterCategory = (name) => {
    const subCollections = [];
    backUpCategory.forEach((collection) => {
      if (collection?.description && collection.description === name) {
        subCollections.push(collection);
      }
    });
    setSubCats(subCollections);
  };

  const allCategory = () => {
    setSubCats(backUpCategory);
  };

  return (
    <>
      <section className="pt-2 tab-section">
        <div id="estimate-header">
          <div className="back-links">
            <a onClick={() => navigate("/categories")}>
              <i className="iconly-Arrow-Left icli"></i>
            </a>
          </div>
          <div className="content">
            <h2>{mainCatgeory}</h2>
          </div>
        </div>
        <div className="tab-section">
          <ul className="nav nav-tabs theme-tab pl-15">
            <li className="nav-item">
              <button
                className="nav-link active"
                data-bs-toggle="tab"
                data-bs-target="#trending"
                type="button"
                onClick={() => allCategory()}
              >
                All
              </button>
            </li>
            {filters.map((val, index) => {
              return (
                <li key={index} className="nav-item">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#top"
                    type="button"
                    onClick={() => filterCategory(val)}
                  >
                    {val}
                  </button>
                </li>
              );
            })}
          </ul>
          <div className="tab-content px-15">
            <div className="tab-pane fade show active">
              <div className="row gy-3 gx-3">
                {subCats.map((val, idx) => (
                  <div key={idx} className="col-md-4 col-6">
                    <div
                      onClick={() =>
                        navigate(
                          "/product-list?categoryName=" +
                            val?.subTitle +
                            "&categoryId=" +
                            val?.mainId
                        )
                      }
                      className="product-box ratio_square"
                    >
                      <div className="img-part">
                        <a>
                          <img
                            src={
                              val?.image?.url || "assets/images/products/4.jpg"
                            }
                            alt=""
                            className="img-fluid bg-img"
                          />
                        </a>
                      </div>
                      <div className="product-content">
                        <a>
                          <h4>{val.subTitle}</h4>
                        </a>
                        {/* <div className="price">
                          <h4>subtitle</h4>
                        </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default SubCategoryGrid;
