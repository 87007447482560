/* eslint-disable no-undef */
import React from "react";

function ProductLoader() {
  return (
    <div id="estimate-product-loader">
      <img src="assets/images/ajax-loader.gif" className="img-fluid" alt="" />
    </div>
  );
}

export default ProductLoader;
