/* eslint-disable no-undef */
import React from "react";

function Loader() {
  return (
    <div className="loader">
      <span></span>
      <span></span>
    </div>
  );
}

export default Loader;
