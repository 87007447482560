/* eslint-disable no-undef */
import React from "react";

function ApiLoader() {
  return (
    <div className="apiLoader">
      <span></span>
      <span></span>
    </div>
  );
}

export default ApiLoader;
