/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import AppContext from "../Utils/appContext";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { extractIdFromGid } from "../Utils/categories-divide";
const similarProductSlider = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "60px",
  dots: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 365,
      settings: {
        slidesToShow: 2,
        centerPadding: "20px",
      },
    },
  ],
};
function SimilarProduct({ recommendedProducts }) {
  const navigate = useNavigate();
  const { showPrice } = React.useContext(AppContext);
  //   we have set the products on the basis of category id as key value

  const goToProduct = (id) => {
    const productId = extractIdFromGid(id);
    navigate("/product?productId=" + productId);
  };
  return (
    <>
      <Slider
        className={"product-slider slick-default pl-15"}
        {...similarProductSlider}
      >
        {recommendedProducts?.length > 0 &&
          recommendedProducts?.map((product, idx) => {
            return (
              <div key={idx}>
                <div
                  onClick={() => goToProduct(product.id)}
                  className="product-box ratio_square"
                >
                  <div className="img-part">
                    <a>
                      <img
                        src={
                          product?.images?.nodes?.[0]?.url ||
                          "assets/images/products/1000.jpg"
                        }
                        alt={product?.title}
                        className="img-fluid bg-img"
                      />
                    </a>
                    {product?.totalInventory > 0 ? (
                      <label>new</label>
                    ) : (
                      <label>out of stock</label>
                    )}
                  </div>
                  <div className="product-content">
                    <a>
                      <h4 id="estimate-h4-smaller">{product?.title}</h4>
                    </a>
                    {showPrice && (
                      <div className="price">
                        {product?.variants?.nodes?.length > 1 ? (
                          <h4 id="estimate-h4-smaller">
                            ₹{product?.priceRange?.minVariantPrice?.amount}
                            <span> onwards </span>
                            {/* ₹{product?.priceRange?.maxVariantPrice?.amount} */}
                          </h4>
                        ) : (
                          <h4 id="estimate-h4-smaller">
                            ₹{product?.priceRange?.minVariantPrice?.amount}
                          </h4>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </>
  );
}

export default SimilarProduct;
