/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import AppContext from "../Utils/appContext";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import ProductLoader from "../Components/Common/ProductLoader";
import { extractIdFromGid } from "../Utils/categories-divide";
import { getProductsByCategoryId } from "../Utils/api-service";
const similarProductSlider = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "60px",
  dots: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 365,
      settings: {
        slidesToShow: 2,
        centerPadding: "20px",
      },
    },
  ],
};
function NewCategory() {
  const navigate = useNavigate();
  const { otherCategories, showPrice } = React.useContext(AppContext);
  const [categories, setCategories] = React.useState([]);
  //   we have set the products on the basis of category id as key value
  const [products, setProducts] = React.useState({});
  const [categoriesId, setCategoriesId] = React.useState();
  const [loading, setLoading] = React.useState({});
  React.useEffect(() => {
    if (otherCategories.length > 0) {
      const arr = [];
      const catIds = [];
      otherCategories.forEach((cat, indx) => {
        arr[indx] = cat;
        const [, title] = cat?.title.split("__");
        arr[indx]["mainTitle"] = title;
        const id = extractIdFromGid(cat?.id);
        arr[indx]["mainId"] = id;
        catIds.push(id);
      });
      setCategories(arr);
      setCategoriesId(catIds);
    }
  }, [otherCategories]);
  React.useEffect(() => {
    if (categoriesId) {
      fetchProducts(categoriesId);
    }
  }, [categoriesId]);
  const fetchProducts = (ids) => {
    ids.forEach((id) => {
      setLoading((prevLoadings) => ({
        ...prevLoadings,
        [id]: true,
      }));
      try {
        let nextCursor;
        let res = getProductsByCategoryId(id, nextCursor);
        res.then((data) => {
          if (data.status === 200) {
            const ps = data?.data?.collection?.products?.nodes || [];
            setLoading((prevLoadings) => ({
              ...prevLoadings,
              [id]: false,
            }));
            setProducts((prevProducts) => ({
              ...prevProducts,
              [id]: ps,
            }));
          }
        });
      } catch (error) {
        setLoading((prevLoadings) => ({
          ...prevLoadings,
          [id]: false,
        }));
        console.error("Error fetching data:", error);
      }
    });
  };
  const goTo = (category) => {
    return navigate(
      "/product-list?categoryName=" +
        category.mainTitle +
        "&categoryId=" +
        category?.mainId
    );
  };
  const goToProduct = (id) => {
    const productId = extractIdFromGid(id);
    navigate("/product?productId=" + productId);
  };
  return (
    <>
      {categories?.map((category, indx) => {
        return (
          <div key={indx}>
            <section className="deals-section px-15 pt-0">
              <div className="title-part">
                <h2>{category?.mainTitle}</h2>
                <a onClick={() => goTo(category)}>See All</a>
              </div>
              {loading?.[category?.mainId] && <ProductLoader />}
              {!loading?.[category?.mainId] && (
                <Slider
                  className={"product-slider slick-default pl-15"}
                  {...similarProductSlider}
                >
                  {products?.[category?.mainId]?.length > 0 &&
                    products?.[category?.mainId]?.map((product, idx) => {
                      return (
                        <div key={idx}>
                          <div
                            onClick={() => goToProduct(product.id)}
                            className="product-box ratio_square"
                          >
                            <div className="img-part">
                              <a>
                                <img
                                  src={
                                    product?.featuredImage?.src ||
                                    "assets/images/products/1000.jpg"
                                  }
                                  alt={product?.featuredImage?.altText || ""}
                                  className="img-fluid bg-img"
                                />
                              </a>
                              {product?.totalInventory > 0 ? (
                                <label>new</label>
                              ) : (
                                <label>out of stock</label>
                              )}
                            </div>
                            <div className="product-content">
                              <a>
                                <h4>{product?.title}</h4>
                              </a>
                              {showPrice && (
                                <div className="price">
                                  {product?.variants?.nodes?.length > 1 ? (
                                    <h4>
                                      ₹
                                      {
                                        product?.priceRange?.minVariantPrice
                                          ?.amount
                                      }
                                      <span> onwards </span>
                                      {/* ₹{product?.priceRange?.maxVariantPrice?.amount} */}
                                    </h4>
                                  ) : (
                                    <h4>
                                      ₹
                                      {
                                        product?.priceRange?.minVariantPrice
                                          ?.amount
                                      }
                                    </h4>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              )}
            </section>
            <div className="divider"></div>
          </div>
        );
      })}
    </>
  );
}

export default NewCategory;
