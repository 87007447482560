import { getUserTokenVerify } from "./api-service";
import { getLocalStorage } from "./util-service";

export const checkTokenAvailable = () => {
  const authToken = getLocalStorage("authToken");
  if (!authToken) {
    return false;
  }
  return authToken !== "null";
};
export const validateAccessToken = async () => {
  try {
    const authToken = getLocalStorage("authToken");
    let val = false;
    const response = await getUserTokenVerify(authToken);
    if (response && response.status === 200) {
      val = response.data;
      return val;
    } else {
      console.log("Error occurred:", response);
      return val;
    }
  } catch (error) {
    console.error("Error occurred during token validation:", error);
    return false;
  }
};
