/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Loader from "../Components/Common/Loader";
import { getCart, setCart } from "../Utils/cart-service";
import EmptyCart from "../Components/Common/EmptyCart";
import { useNavigate } from "react-router-dom";
import AppContext from "../Utils/appContext";
import { possibleNames } from "../Utils/constant";
import HeaderOptions from "../Components/Common/HeaderOptions";
import { extractIdFromGid } from "../Utils/categories-divide";

export default function Cart() {
  const { showPrice, setCartLength } = React.useContext(AppContext);
  const [fullCart, setFullCart] = React.useState(getCart());
  const [showLoad, setShowLoad] = React.useState(true);
  const [removeProduct, setRemoveProduct] = React.useState();
  const [changeQuantity, setChangeQuantity] = React.useState();
  const [selectedQuantity, setSelectedQuantity] = React.useState(1);
  const [totalAmount, setTotalAmount] = React.useState();
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      setShowLoad(false);
    }, 800);
  }, []);
  React.useEffect(() => {
    let total = 0;
    fullCart?.forEach((product) => {
      total += product.totalPrice;
    });
    setTotalAmount(total);
    console.log("fullCart", fullCart);
  }, [fullCart]);

  const changeQuantityOfProduct = () => {
    if (changeQuantity === undefined) {
      return;
    }
    const cart = getCart();
    if (changeQuantity >= 0 && changeQuantity < cart.length) {
      cart[changeQuantity].quantity = selectedQuantity;
      cart[changeQuantity].totalPrice =
        cart[changeQuantity].quantity * cart[changeQuantity].price;
      setCart(cart);
    }
    setTimeout(() => {
      setFullCart(getCart());
    }, 100);
  };

  const handleIncrement = () => {
    setSelectedQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setSelectedQuantity((prevQuantity) =>
      prevQuantity > 1 ? prevQuantity - 1 : 1
    );
  };
  const removeFromCart = () => {
    if (removeProduct === undefined) {
      return;
    }
    setFullCart((prevCart) => {
      const newCart = prevCart.filter((_, i) => i !== removeProduct);
      setCart(newCart);
      setCartLength(newCart.length);
      return newCart;
    });
  };

  const goToProduct = (id) => {
    const productId = extractIdFromGid(id);
    navigate("/product?productId=" + productId);
  };
  return fullCart?.length === 0 ? (
    <EmptyCart />
  ) : (
    <>
      {showLoad && <Loader />}
      <header>
        <div className="back-links">
          <a onClick={() => navigate(-1)}>
            <i className="iconly-Arrow-Left icli"></i>
            <div className="content">
              <h2>Your Cart</h2>
              <h6>Step 1 of 2</h6>
            </div>
          </a>
        </div>
        <HeaderOptions hideCart={true} hideProfile={true} showHome={true} />
      </header>
      <section className="cart-section pt-10 top-space">
        {fullCart.map((product, idx) => {
          return (
            <div key={idx}>
              <div className="cart-box px-15">
                <a
                  onClick={() => goToProduct(product?.id)}
                  className="cart-img"
                >
                  <img
                    src={
                      product?.images?.[0]?.url ||
                      "assets/images/products/1000.jpg"
                    }
                    className="img-fluid"
                    alt=""
                  />
                </a>
                <div className="cart-content">
                  <a>
                    <h4>{product?.title}</h4>
                  </a>
                  {product?.vendor && (
                    <h5 className="content-color">by {product?.vendor}</h5>
                  )}
                  <div key={idx} className="select-size-sec">
                    {product?.options &&
                      product?.options?.map((option, idx) => {
                        if (option?.name === "Title") {
                          return null;
                        }
                        if (
                          possibleNames.includes(option?.name.toLowerCase())
                        ) {
                          return null;
                        }
                        return (
                          <a key={idx} className="opion">
                            <h6>
                              {option?.name}:{"  "}
                              {product?.selectedOption[idx]}
                            </h6>
                          </a>
                        );
                      })}
                  </div>
                  {showPrice && (
                    <div id="estimate-flex-space-arnd" className="price">
                      <h4 id="price-15" className="text-green">
                        ₹<strong>{product?.totalPrice}</strong>
                      </h4>
                      <div>
                        <i
                          id="color-orange"
                          className="iconly-Arrow-Right icli"
                        ></i>
                      </div>
                      {product?.totalPc &&
                        product?.quantity &&
                        product?.unit &&
                        product?.price && (
                          <strong>
                            <h5
                              id="estimate-cart-qty"
                              className="content-color"
                            >
                              ({product?.totalPc * product?.quantity}
                              {product?.unit}
                              {` @ ₹${(
                                product?.price / product?.totalPc
                              ).toFixed(2)} per ${product?.unit}`}
                              )
                            </h5>
                          </strong>
                        )}
                    </div>
                  )}
                </div>
              </div>
              <div id="estimate-cart-edit" className="cart-option">
                <h5
                  onClick={() => setRemoveProduct(idx)}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#removecart"
                >
                  <i id="estimate-red" className="iconly-Delete icli"></i>
                  Remove
                </h5>
                <span className="divider-cls">|</span>
                <div className="select-size-sec">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#selectQty"
                    onClick={() => {
                      setChangeQuantity(idx);
                      setSelectedQuantity(product?.quantity);
                    }}
                    className="opion"
                  >
                    <i
                      id="estimate-red"
                      className="iconly-Edit icli esti-mr-4"
                    ></i>
                    <h6>
                      {" "}
                      <strong>
                        Pack Size {product?.totalPc} {product?.unit}{" "}
                      </strong>
                      <i className="iconly-Arrow-Right icli"></i> Qty:{" "}
                      {product?.quantity}
                    </h6>
                    <i className="iconly-Arrow-Down-2 icli"></i>
                  </a>
                </div>
                {/* <h5 data-bs-toggle="offcanvas">
                      <i className="iconly-Edit icli"></i> Edit
                    </h5> */}
              </div>
              <div id="estimate-divider" className="divider"></div>
            </div>
          );
        })}
      </section>
      {/* Bag details */}
      <section id="order-details" className="px-15 pt-0">
        <h2 className="title">Estimate Details:</h2>
        <div className="order-details">
          {/* <ul>
            <li>
              <h4>
                Total cart quantity <span>55</span>
              </h4>
            </li>
          </ul> */}
          {showPrice && (
            <div className="total-amount">
              <h4>
                Estimated total
                <span>₹{totalAmount}</span>
              </h4>
            </div>
          )}
          <div className="delivery-info">
            <img src="assets/images/truck.gif" className="img-fluid" alt="" />
            <h4>
              Next Step is to select address before you send the estimate query
              to us.{" "}
            </h4>
          </div>
        </div>
      </section>
      <div className="divider"></div>

      <section className="service-wrapper px-15 pt-0">
        <div className="row">
          <div className="col-4">
            <div className="service-wrap">
              <div className="icon-box">
                <img
                  src="assets/svg/about/diamond.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <span>Super Quality</span>
            </div>
          </div>
          <div className="col-4">
            <div className="service-wrap">
              <div className="icon-box">
                <img
                  src="assets/svg/24-hours.svg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <span>24/7 Support</span>
            </div>
          </div>
          <div className="col-4">
            <div className="service-wrap">
              <div className="icon-box">
                <img src="assets/svg/wallet.svg" className="img-fluid" alt="" />
              </div>
              <span>Best Price</span>
            </div>
          </div>
        </div>
      </section>
      <section className="panel-space"></section>
      <div id="estimate-zindex-2" className="cart-bottom">
        <div>
          {showPrice && (
            <div className="left-content">
              <h4>₹{totalAmount}</h4>
            </div>
          )}
          <a onClick={() => navigate("/delivery")} className="btn btn-solid">
            Select Address
          </a>
        </div>
      </div>
      {/* Select Quantity */}
      <div
        className="offcanvas offcanvas-bottom h-auto qty-canvas"
        id="selectQty"
      >
        <div className="offcanvas-body small">
          <h4>Select Quanity:</h4>
          <div className="qty-counter">
            <div className="input-group">
              <button
                type="button"
                className="btn quantity-left-minus"
                onClick={handleDecrement}
              >
                <img
                  src="assets/svg/minus-square.svg"
                  className="img-fluid"
                  alt=""
                />
              </button>
              <input
                type="text"
                name="quantity"
                className="form-control form-theme qty-input input-number"
                value={selectedQuantity}
                readOnly
              />
              <button
                type="button"
                className="btn quantity-right-plus"
                onClick={handleIncrement}
              >
                <img
                  src="assets/svg/plus-square.svg"
                  className="img-fluid"
                  alt=""
                />
              </button>
            </div>
          </div>
          <a
            onClick={changeQuantityOfProduct}
            className="btn btn-solid w-100"
            data-bs-dismiss="offcanvas"
          >
            update
          </a>
        </div>
      </div>

      {/* <!-- remove item canvas start --> */}
      <div
        className="offcanvas offcanvas-bottom h-auto removecart-canvas"
        id="removecart"
      >
        <div className="offcanvas-body small">
          <div className="content">
            <h4>Remove Item:</h4>
            <p>
              Are you sure you want to remove or move this item from the cart ?
            </p>
          </div>
          <div className="bottom-cart-panel">
            <div className="row">
              <div
                data-bs-toggle="offcanvas"
                data-bs-target="#removecart"
                onClick={removeFromCart}
                className="col-12"
              >
                <a className="theme-color">REMOVE</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- remove item canvas end --> */}
    </>
  );
}
