import * as React from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { clearCart } from "../Utils/cart-service";
import AppContext from "../Utils/appContext";

export default function OrderSuccess() {
  const { setCartLength } = React.useContext(AppContext);
  let [searchParams] = useSearchParams();
  const location = useLocation();
  const data = location.state;
  clearCart();
  setCartLength(0);
  const [estimateId] = React.useState(searchParams.get("estimateId") || "");
  return (
    <>
      {/* <Loader /> */}
      <section className="order-success-section px-15 top-space">
        <div>
          <div id="estimate-order-success" className="check-circle">
            <img
              src="assets/images/check-circle.gif"
              className="img-fluid"
              alt=""
            />
          </div>
          <h1>Congratulations!</h1>
          <h2>Estimate is successfully sent and your items is under review.</h2>
        </div>
      </section>
      <section className="px-15">
        <h2 className="page-title">Estimate Details</h2>
        <div className="details">
          <ul>
            <li className="mb-3 d-block">
              <h4 className="fw-bold mb-1">
                Your estimate ticket is: {estimateId}
              </h4>
            </li>
            {data?.shippingAddress && data?.shippingAddress.length > 0 && (
              <li className="mb-3 d-block">
                <h4 className="fw-bold mb-1">This estimate will be send to:</h4>
                <h4 className="content-color">{data?.shippingAddress}</h4>
              </li>
            )}
            {data?.deliveryInstruction &&
              data?.deliveryInstruction.length > 0 && (
                <li className="mb-3 d-block">
                  <h4 className="fw-bold mb-1">Your Delivery Instruction</h4>
                  <h4 className="content-color">{data?.deliveryInstruction}</h4>
                </li>
              )}
          </ul>
        </div>
        <div className="divider"></div>
      </section>
      <div className="divider"></div>
    </>
  );
}
