import * as React from "react";
import { useLoaderData } from "react-router-dom";
import CategoryGrid from "../Components/CategoryGrid";
import SimpleSlide from "../Components/Common/SimpleSlide";
import ApiLoader from "../Components/Common/ApiLoader";
import AppContext from "../Utils/appContext";
import { divideCategories } from "../Utils/categories-divide";
import NewCategory from "../Components/NewCategory";
// import RoundCategories from "../Components/RoundCategories";

export default function Home() {
  const { setCategories, setSubCategories, setOtherCategories } =
    React.useContext(AppContext);
  const {
    data: { collections },
    status,
  } = useLoaderData();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (status === 200) {
      const { parentCategories, childCategories, otherCategories } =
        divideCategories(collections?.nodes);
      setCategories(parentCategories);
      setSubCategories(childCategories);
      setOtherCategories(otherCategories);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections]);

  return loading ? (
    <ApiLoader />
  ) : (
    <>
      <div className="divider t-12 b-20"></div>
      {/* <RoundCategories /> */}
      <div className="divider t-12 b-20"></div>
      <SimpleSlide />
      <div className="divider t-12 b-20"></div>
      <CategoryGrid />
      <div className="divider t-12 b-20"></div>
      <NewCategory />
      <div className="divider t-12 b-20"></div>
    </>
  );
}
