import * as React from "react";
import Header from "../Components/Header";
import BottonNavigation from "../Components/BottonNavigation";
import AppContext from "../Utils/appContext";
import { getPrice } from "../Utils/price-service";
import { useLocation } from "react-router-dom";
import { limitApiCall } from "../Utils/util-service";
import { validateAccessToken } from "../Utils/verify-user";

export default function MainLayout({ component: Component, ...rest }) {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const offcanvasElement = document.querySelector(".offcanvas");
    const backdropElement = document.querySelector(".offcanvas-backdrop");
    const modalElement = document.querySelector(".modal-backdrop");
    if (offcanvasElement) {
      offcanvasElement.classList.remove("show");
      offcanvasElement.setAttribute("aria-hidden", "true");
    }

    if (backdropElement) {
      backdropElement.remove();
    }

    if (modalElement) {
      modalElement.remove();
    }
    document.body.removeAttribute("class");
    document.body.removeAttribute("style");
  }, [pathname]);
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [otherCategories, setOtherCategories] = React.useState([]);
  const [cartLength, setCartLength] = React.useState();
  const [showPrice, setShowPrice] = React.useState(getPrice());
  const value = React.useMemo(
    () => ({
      categories,
      setCategories,
      subCategories,
      setSubCategories,
      otherCategories,
      setOtherCategories,
      cartLength,
      setCartLength,
      showPrice,
      setShowPrice,
    }),
    [categories, otherCategories, subCategories, cartLength, showPrice]
  );

  // theme provider
  //   const [mode, setMode] = React.useState("light");
  //   const [isAuthenticated, setIsAuthenticated] = React.useState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function checkAuthentication() {
    try {
      const flag = await validateAccessToken();
      localStorage.setItem("name", flag?.name);
      localStorage.setItem("phone", flag?.phone);
      if (flag?.showPrice === false) {
        localStorage.setItem("price", flag?.showPrice);
      }
      localStorage.setItem("vId", flag?.vendorID);
      localStorage.setItem("isShowPriceEnabled", flag?.showPrice);
      return flag;
    } catch (error) {
      console.error("Error occurred while checking authentication:", error);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const limitSearch = React.useCallback(limitApiCall(checkAuthentication), []);
  React.useEffect(() => {
    limitSearch();
    return () => {
      // Clean-up code (if needed)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <AppContext.Provider value={value}>
        <Header />
        <Component {...rest} />
        <BottonNavigation />
      </AppContext.Provider>
    </>
  );
}
